import axiosIns from '@/libs/axios'

const getAxios = () => axiosIns.get('/accountingOperations')
const postAxios = data => axiosIns.post('/accountingOperations', data)
const oneAxios = id => axiosIns.get(`/accountingOperations/${id}`)
const putAxios = (id, data) => axiosIns.put(`/accountingOperations/${id}`, data)
const deleteAxios = id => axiosIns.delete(`/accountingOperations/${id}`)
const searchByDocumentAxios = id => axiosIns.get(`/accountingOperations/searchByDocument/${id}`)

export default {
  getAxios,
  postAxios,
  oneAxios,
  putAxios,
  deleteAxios,
  searchByDocumentAxios,
}
