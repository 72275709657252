<template>
  <section class="invoice-preview-wrapper">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="invoiceData === undefined"
    >
      <h4 class="alert-heading">
        {{ $t('InvoiceInfo.Error fetching invoice data') }}
      </h4>
      <div class="alert-body">
        {{ `${$t('InvoiceInfo.No invoice found with this invoice id')} . ${$t('InvoiceInfo.Check')}` }}
        <b-link
          class="alert-link"
          :to="{ name: 'sale-invoice'}"
        >
          {{ $t('InvoiceInfo.Invoice List') }}
        </b-link>
        {{ $t('InvoiceInfo.for other invoices') }}.
      </div>
    </b-alert>

    <b-row
      v-if="invoiceData"
      class="invoice-preview"
    >

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-overlay
          :show="showOverLay"
          rounded="sm"
          :opacity="0.5"
        >
          <b-card
            no-body
            class="invoice-preview-card"
          >
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">

              <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

                <!-- Header: Left Content -->
                <div>
                  <div class="logo-wrapper">
                    <logo />
                    <h3 class="text-primary invoice-logo">
                      {{ invoiceData.documentName }}
                    </h3>
                  </div>
                  <h4>
                    {{ $store.state.appConfig.layout.isRTL ? one_organization.name_ar : one_organization.name_en }}
                  </h4>
                  <p class="card-text mb-25">
                    {{ one_organization.address }}
                  </p>
                  <p class="card-text mb-25">
                    {{ one_organization.email }}, {{ one_organization.website }}
                  </p>
                  <p class="card-text mb-0">
                    {{ one_organization.phone_one }}, {{ one_organization.phone_tow }}
                  </p>
                </div>

                <!-- Header: Right Content -->
                <div class="mt-md-0 mt-2">
                  <h4 class="invoice-title">
                    {{ $t('General.Number') }}
                    <span class="invoice-number">#{{ invoiceData.master.document_no }}</span>
                  </h4>
                  <div class="invoice-date-wrapper">
                    <p class="invoice-date-title">
                      {{ $t('InvoiceInfo.Date Issued') }}:
                    </p>
                    <p class="invoice-date">
                      {{ new Date(invoiceData.master.date).toLocaleDateString() }}
                    </p>
                  </div>
                  <div class="invoice-date-wrapper">
                    <p class="invoice-date-title">
                      {{ $t('InvoiceInfo.Due Date') }}:
                    </p>
                    <p class="invoice-date">
                      {{ new Date(invoiceData.master.due_date).toLocaleDateString() }}
                    </p>
                  </div>
                </div>
              </div>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing">

            <!-- Invoice Client & Payment Details -->
            <!-- v-if="client" -->
            <b-card-body
              v-if="client"
              class="invoice-padding pt-0"
            >
              <b-row class="invoice-spacing">

                <!-- Col: Invoice To -->
                <b-col
                  cols="12"
                  xl="6"
                  class="p-0"
                >
                  <h6 class="mb-2">
                    {{ invoiceData.to }}:
                  </h6>
                  <h6 class="mb-25">
                    {{ $store.state.appConfig.layout.isRTL ? client.name_ar : client.name_en }}
                  </h6>
                  <p
                    v-if="client.company"
                    class="card-text mb-25"
                  >
                    {{ $store.state.appConfig.layout.isRTL ? client.company.name_ar : client.company.name_en }}
                  </p>
                  <p class="card-text mb-25">
                    {{ client.address }}, {{ client.area_id }}
                  </p>
                  <p class="card-text mb-25">
                    {{ client.phone }}, {{ client.tel }}
                  </p>
                  <p class="card-text mb-0">
                    {{ client.email }}
                  </p>
                </b-col>

                <!-- Col: Payment Details -->
                <b-col
                  xl="6"
                  cols="12"
                  class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end"
                >
                  <div>
                    <h6 class="mb-2">
                      {{ `${$t('Payment')} ${$t('Details')}: ` }}
                    </h6>
                    <table>
                      <tbody>
                        <tr>
                          <td class="pr-1">
                            {{ $t('InvoiceInfo.Total Due') }}:
                          </td>
                          <td><span class="font-weight-bold">{{ invoiceData.master.total_price }}£</span></td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                            {{ $t('General.Balance') }}:
                          </td>
                          <td><span class="font-weight-bold">{{ invoiceData.balance }}£</span></td>
                        </tr>
                        <tr v-if="client.bankbranch">
                          <td class="pr-1">
                            {{ `${$t('Bank')} ${$t('General.Name')}: ` }}
                          </td>
                          <td>{{ $store.state.appConfig.layout.isRTL ? client.bankbranch.bank.name_ar : client.bankbranch.bank.name_en }}</td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                            {{ $t('General.IBAN') }}:
                          </td>
                          <td>{{ client.IBAN_account_number }}</td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                            {{ `${$t('Bank')} ${$t('General.Card')}: ` }}
                          </td>
                          <td>{{ client.bank_card }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Invoice Description: Table -->
            <b-table-lite
              responsive
              :items="invoiceData.items"
              :fields="['item', 'measurementUnit', 'price', 'quantity', 'total']"
            >
              <template #cell(item)="data">
                <b-card-text class="font-weight-bold mb-25">
                  {{ $store.state.appConfig.layout.isRTL ? data.item.item.name_ar : data.item.item.name_en }}
                </b-card-text>
              </template>
              <template #cell(measurementUnit)="data">
                <b-card-text class="text-nowrap">
                  {{ $store.state.appConfig.layout.isRTL ? data.item.measurement_unit.name_ar : data.item.measurement_unit.name_en }}
                </b-card-text>
              </template>
              <template #cell(total)="data">
                <b-card-text class="font-weight-bold mb-25">
                  {{ data.item.price * data.item.quantity }}
                </b-card-text>
              </template>
            </b-table-lite>

            <!-- Invoice Description: Total -->
            <b-card-body class="invoice-padding pb-0">
              <b-row>

                <!-- Col: Sales Persion -->
                <b-col
                  cols="12"
                  md="6"
                  class="mt-md-0 mt-3"
                  order="2"
                  order-md="1"
                >
                  <b-card-text class="mb-0">
                    <span class="font-weight-bold">{{ $t('Warehouse') }}:</span>
                    <span class="ml-75">
                      {{ $store.state.appConfig.layout.isRTL ? invoiceData.master.warehouse.name_ar : invoiceData.master.warehouse.name_en }}
                    </span>
                  </b-card-text>
                  <b-card-text
                    v-if="invoiceData.master.based_document_id"
                    class="mb-0"
                  >
                    <span class="font-weight-bold">{{ $t('InvoiceInfo.Documented based on') }}:</span>
                    <span class="ml-75">
                      {{ $store.state.appConfig.layout.isRTL ? one_document.name_ar : one_document.name_en }}
                    </span>
                  </b-card-text>
                  <b-card-text
                    v-if="invoiceData.master.based_document_id"
                    class="mb-0"
                  >
                    <span class="font-weight-bold">{{ $t('InvoiceInfo.Document No') }}:</span>
                    <span class="ml-75">
                      {{ invoiceData.master.based_document_no }}
                    </span>
                  </b-card-text>
                </b-col>

                <!-- Col: Total -->
                <b-col
                  cols="12"
                  md="6"
                  class="mt-md-6 d-flex justify-content-end"
                  order="1"
                  order-md="2"
                >
                  <div class="invoice-total-wrapper">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        {{ $t('InvoiceInfo.Subtotal') }}:
                      </p>
                      <p class="invoice-total-amount">
                        {{ invoiceData.master.total_price }}£
                      </p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        {{ $t('InvoiceInfo.Discount') }}:
                      </p>
                      <p class="invoice-total-amount">
                        0£
                      </p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        {{ $t('InvoiceInfo.Tax') }}:
                      </p>
                      <p class="invoice-total-amount">
                        0%
                      </p>
                    </div>
                    <hr class="my-50">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        {{ $t('InvoiceInfo.Total') }}:
                      </p>
                      <p class="invoice-total-amount">
                        {{ invoiceData.master.total_price }}£
                      </p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing">

            <!-- Note -->
            <b-card-body class="invoice-padding pt-0">
              <span class="font-weight-bold">{{ $t('General.Notes') }}: </span>
              <span> {{ invoiceData.master.description }}</span>
            </b-card-body>
          </b-card>
        </b-overlay>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card>

          <!-- Button: Send Invoice -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-b-toggle.sidebar-send-invoice
            variant="primary"
            class="mb-75"
            block
          >
            {{ `${$t('General.Send')} ${$t('General.Email')}` }}
          </b-button>

          <!-- Button: DOwnload -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
          >
            {{ $t('General.Download') }}
          </b-button>

          <!-- Button: Print -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            @click="printInvoice"
          >
            {{ $t('General.Print') }}
          </b-button>

          <!-- Button: Edit -->
          <b-button
            v-if="$can('update', invoiceData.permissionEdit)"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            :to="{ name: invoiceData.routEdit, params: { id: $route.params.id } }"
          >
            {{ $t('General.Edit') }}
          </b-button>

          <!-- Button: Add Payment -->
          <b-button
            v-if="invoiceData.payment && $can('add', 'PaymentInvoice')"
            v-b-toggle.sidebar-invoice-add-payment
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            class="mb-75"
            block
          >
            {{ $t('Payment') }}
          </b-button>
        </b-card>

        <b-card v-if="payment_operation_option & payment_operation_option.length > 0">
          <b-table-lite
            responsive
            :items="payment_operation_option"
            :fields="['payment', 'amount']"
          >
            <template #cell(payment)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ $store.state.appConfig.layout.isRTL ? data.item.payment_operation.name_ar : data.item.payment_operation.name_en }}
              </b-card-text>
            </template>
          </b-table-lite>
        </b-card>
      </b-col>
    </b-row>

    <invoice-sidebar-send-invoice />
    <invoice-sidebar-add-payment
      :invoice-data="invoiceData"
    />
  </section>
</template>

<script>
// import { onUnmounted } from '@vue/composition-api'
// import store from '@/store'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import {
  BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle, BOverlay,
} from 'bootstrap-vue'
import Logo from '@core/layouts/components/Logo.vue'
import Ripple from 'vue-ripple-directive'
import supplier from '@/service/crm/supplier'
import branch from '@/service/administration/branch'
import document from '@/service/administration/document'
import bankBranch from '@/service/administration/bank-branch'
import paymentOperation from '@/service/warehouse/payment-operation'
import payment from '@/service/administration/payment'
// import invoiceStoreModule from '../invoiceStoreModule'
import InvoiceSidebarSendInvoice from '../InvoiceSidebarSendInvoice.vue'
import InvoiceSidebarAddPayment from '../InvoiceSidebarAddPayment.vue'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    BOverlay,
    Logo,
    InvoiceSidebarAddPayment,
    InvoiceSidebarSendInvoice,
  },
  props: {
    invoiceData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      id: router.currentRoute.params.id,
      marge_operation: [],
      one_organization: '',
      one_document: '',
      client: [],
      bank_branch_option: [],
      operation_details: [],
      payment_option: [],
      showError: false,
      showOverLay: false,
      apiErrors: [],
      balance: '',
      payment_operation_option: [],
      payment_table: [],
    }
  },
  created() {
    this.showOverLay = true
    this.getApiSelect()
    this.invoiceData.master.description = this.invoiceData.master.description ? this.invoiceData.master.description : ''
    // setTimeout(() => { this.getBalance() }, 2000)
  },
  // async setup() {
  //   // this.getApiSelect()

  //   // Invoice Description
  //   // ? Your real data will contain this information
  //   const invoiceDescription = [
  //     {
  //       taskTitle: 'Native App Development',
  //       taskDescription: 'Developed a full stack native app using React Native, Bootstrap & Python',
  //       rate: '$60.00',
  //       hours: '30',
  //       total: '$1,800.00',
  //     },
  //     {
  //       taskTitle: 'UI Kit Design',
  //       taskDescription: 'Designed a UI kit for native app using Sketch, Figma & Adobe XD',
  //       rate: '$60.00',
  //       hours: '20',
  //       total: '$1200.00',
  //     },
  //   ]

  //   const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

  //   // Register module
  //   if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)

  //   // UnRegister on leave
  //   onUnmounted(() => {
  //     if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
  //   })
  //   const printInvoice = () => {
  //     window.print()
  //   }

  //   return {
  //     invoiceDescription,
  //     printInvoice,
  //   }
  // },
  methods: {
    async getApiSelect() {
      await payment.getAxios().then(response => {
        this.payment_option = response.data
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      })
      await supplier.oneAxios(this.invoiceData.master.vendor_set_id).then(response => {
        this.client = response.data
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      })
      await branch.oneAxios(this.invoiceData.master.organization_branch_id).then(response => {
        this.one_organization = response.data.organization
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      })
      await bankBranch.oneAxios(this.client.bank_branch_id).then(response => {
        this.client.bankbranch = { ...response.data }
        //  = { ...this.client, bankbranch }
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      })
      await document.oneAxios(this.invoiceData.master.based_document_type_id).then(response => {
        this.one_document = response.data
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      })
      await document.oneAxios(this.invoiceData.document_id).then(response => {
        this.invoiceData.document = response.data
        this.invoiceData.payment = response.data.is_payment
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      }).finally(() => {
        this.getBalance()
        this.showOverLay = false
      })
      // setTimeout(() => {
      //   this.client = this.vendor_set_option.find(x => x.id === this.invoiceData.vendor_set_id)
      //   // this.operation_details = this.invoiceData.operation_details
      // }, 100)
      // setTimeout(() => {
      //   const bankbranch = this.bank_branch_option.find(x => x.id === )
      // }, 500)
    },
    printInvoice() {
      window.print()
    },
    async getBalance() {
      await paymentOperation.searchByDocument(this.invoiceData.document_id, this.invoiceData.master.id).then(response => {
        if (response.data.length !== 0) {
          this.payment_operation_option = response.data
          this.invoiceData.balance = this.invoiceData.master.total_price - (this.payment_operation_option.reduce((a, b) => +a + +b.amount, 0))
        } else {
          this.invoiceData.balance = this.invoiceData.master.total_price
        }
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      })
      setTimeout(() => {
        this.payment_operation_option = this.payment_operation_option.map(vendor => {
          // eslint-disable-next-line camelcase
          const payment_operation = this.payment_option.find(x => x.id === vendor.payment_id)
          return { ...vendor, payment_operation }
        })
      }, 1000)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
              > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
