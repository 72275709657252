<template>
  <b-sidebar
    id="sidebar-invoice-add-payment"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add Payment
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- Body -->
      <validation-observer ref="simpleRules">
        <b-overlay
          :show="invoiceData.showOverLay"
          rounded="sm"
          :opacity="0.5"
        >
          <b-form
            ref="formName"
            class="p-2"
            @submit.prevent
          >

            <!-- Invoice Balance -->
            <b-form-group
              label="Invoice Balance"
              label-for="invoice-balance"
            >
              <b-form-input
                id="invoice-balance"
                v-model="invoiceData.balance"
                trim
                disabled
              />
            </b-form-group>

            <!-- Payment Amount -->
            <b-form-group
              label="Payment Amount"
              label-for="payment-amount"
            >
              <validation-provider
                #default="{ errors }"
                name="Payment Amount"
                rules="required"
              >
                <b-form-input
                  id="payment-amount"
                  v-model="amount"
                  placeholder="10000£"
                  trim
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Payment Date -->
            <b-form-group
              label="Payment Date"
              label-for="payment-date"
            >
              <validation-provider
                #default="{ errors }"
                name="Payment Date"
                rules="required"
              >
                <flat-pickr
                  v-model="date"
                  class="form-control"
                  placeholder="Payment Date"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Payment Method"
              label-for="payment-method"
            >
              <v-select
                v-model="payment_id"
                :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                :options="payment_option"
                :label="$store.state.appConfig.layout.isRTL ? 'name_ar' : 'name_en'"
                placeholder="Select Payment Method"
                input-id="payment-method"
                :clearable="false"
                @input="val => selectPayment()"
              />
            </b-form-group>

            <!-- Payment statement -->
            <b-form-group
              label="Payment Statement"
              label-for="payment-statement"
            >
              <validation-provider
                #default="{ errors }"
                name="Payment Statement"
                rules="required"
              >
                <b-form-input
                  id="payment-statement"
                  v-model="statement"
                  placeholder="Payment Statement"
                  trim
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Internal Payment Note -->
            <b-form-group
              label="Internal Payment Note"
              label-for="internal-payment-note"
            >
              <b-form-textarea
                id="internal-payment-note"
                v-model="notes"
                placeholder="Internal Payment Note"
                rows="5"
                trim
              />
            </b-form-group>
            <!-- alert -->
            <b-alert
              v-height-fade.appear
              variant="danger"
              :show="invoiceData.showError"
              class="text-left p-1"
            >
              <ul class="list-style-icons mb-0">
                <li><feather-icon icon="ArrowRightIcon" />{{ invoiceData.apiErrors }}</li>
              </ul>
            </b-alert>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
                @click="validationForm"
              >
                Save
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                @click="hide"
              >
                Cancel
              </b-button>
            </div>
          </b-form>
        </b-overlay>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BButton, BOverlay, BAlert,
} from 'bootstrap-vue'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import { heightFade } from '@core/directives/animations'
import vSelect from 'vue-select'
import payment from '@/service/administration/payment'
import paymentOperation from '@/service/warehouse/payment-operation'
import router from '@/router'
import accountingOperation from '@/service/administration/accounting-operation'
import restriction from '@/service/accounting/restriction'
import financialYear from '@/service/accounting/financial-year'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BOverlay,
    BAlert,
    flatPickr,
    vSelect,
  },
  directives: {
    'height-fade': heightFade,
    Ripple,
  },
  props: {
    invoiceData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      id: router.currentRoute.params.id,
      required,
      payment_option: [],
      payment_id: '',
      oneOperation: '',
      payment_operation_option: [],
      amount: '',
      balance: '',
      date: '',
      notes: '',
      statement: '',
      one_accounting_operation: [],
      current_financial_year: '',
      tree_accounts: '',
      restriction_master: '',
      restriction_detail: '',
      isEndRespos: false,
    }
  },
  created() {
    this.getApiSelect()
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.postApi()
        }
      })
    },
    async postApi() {
      this.invoiceData.showOverLay = true
      const user = JSON.parse(localStorage.getItem('userData'))
      await paymentOperation.postAxios({
        date: this.date,
        payment_id: this.payment_id.id,
        amount: this.amount,
        balance: this.invoiceData.balance,
        statement: this.statement,
        notes: this.notes,
        document_id: this.invoiceData.master.id,
        document_type_id: this.invoiceData.document_id,
        organization_branch_id: this.invoiceData.master.organization_branch_id,
        user_id: user.id,
      }).then(() => {
        this.isEndRespos = false
        this.invoiceData.showError = false
        this.selectPayment()
        this.isEndRespos = true
      }).catch(error => {
        this.invoiceData.apiErrors = error
        this.invoiceData.showError = true
        this.isEndRespos = false
      }).finally(() => {
        this.invoiceData.showOverLay = false
      })
      if (this.isEndRespos) {
        this.invoiceData.showOverLay = true
        await restriction.postAxios({
          ...this.restriction_master,
          branch_id: user.employee.branch_id,
          user_id: user.id,
          restrictionDetails: this.restriction_detail,
        }).then(() => {
          this.invoiceData.showError = false
          this.$refs.formName.reset()
          this.isEndRespos = false
          this.showToast(this.$t('General.Successfully'), 'CheckCircleIcon', this.$t('General.SavedSuccessfully'), 'success')
        }).catch(error => {
          this.invoiceData.apiErrors = error
          this.invoiceData.showError = true
          this.showToast(this.$t('General.Error'), 'XCircleIcon', this.$t('General.SavedError'), 'danger')
        }).finally(() => {
          this.clearForm()
          this.invoiceData.showOverLay = false
        })
      }
    },
    clearForm() {
      this.date = ''
      this.payment_id = ''
      this.amount = ''
      this.statement = ''
      this.notes = ''
      this.getBalance()
      this.$refs.simpleRules.reset()
    },
    showToast(title, icon, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      })
    },
    async getApiSelect() {
      this.invoiceData.showOverLay = true
      // await operation.oneAxios(this.id).then(response => {
      //   this.oneOperation = response.data
      //   // this.balance = this.oneOperation.total_price
      // }).catch(error => {
      //   this.apiErrors = error
      //   this.showError = true
      // })
      await accountingOperation.searchByDocumentAxios(this.invoiceData.document_id).then(response => {
        this.one_accounting_operation = response.data
      }).catch(error => {
        this.invoiceData.apiErrors = error
        this.invoiceData.showError = true
      })
      await financialYear.getAxios().then(response => {
        this.current_financial_year = response.data.find(x => x.active === 1)
      }).catch(error => {
        this.invoiceData.apiErrors = error
        this.invoiceData.showError = true
      })
      // await financialYear.currentYearAxios().then(response => {
      //   this.current_financial_year = response.data
      // }).catch(error => {
      //   this.invoiceData.apiErrors = error
      //   this.invoiceData.showError = true
      // })
      await payment.getAxios().then(response => {
        this.payment_option = response.data
      }).catch(error => {
        this.invoiceData.apiErrors = error
        this.invoiceData.showError = true
      }).finally(() => {
        this.getBalance()
        this.invoiceData.showOverLay = false
      })
    },
    async getBalance() {
      await paymentOperation.searchByDocument(this.invoiceData.document_id, this.invoiceData.master.id).then(response => {
        if (response.data.length === 0) {
          this.invoiceData.balance = this.invoiceData.master.total_price
        } else {
          this.payment_operation_option = response.data
          this.invoiceData.balance = this.invoiceData.master.total_price - (this.payment_operation_option.reduce((a, b) => +a + +b.amount, 0))
        }
      }).catch(error => {
        this.invoiceData.apiErrors = error
        this.invoiceData.showError = true
      })
    },
    selectPayment() {
      if (this.one_accounting_operation !== '') {
        this.tree_accounts = this.one_accounting_operation.accounting_operation_details.find(x => x.payment_id === this.payment_id.id)
        this.restriction_master = {
          date: this.date,
          amount: this.amount,
          statement: this.statement,
          notes: this.notes,
          cost_center_id: this.tree_accounts.cost_center_id,
          financial_year_id: this.current_financial_year.id,
          restriction_no: this.invoiceData.master.document_no,
          active: 1,
        }
        this.restriction_detail = [
          {
            account_tree_id: this.tree_accounts.account_credit_id,
            statement: this.statement,
            credit: this.amount,
            debit: 0,
          },
          {
            account_tree_id: this.tree_accounts.account_debit_id,
            statement: this.statement,
            credit: 0,
            debit: this.amount,
          },
        ]
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
