import axiosAccounting from '@/libs/axios-accounting'

const getAxios = () => axiosAccounting.get('/restrictions')
const postAxios = data => axiosAccounting.post('/restrictions', data)
const oneAxios = id => axiosAccounting.get(`/restrictions/${id}`)
const putAxios = (id, data) => axiosAccounting.put(`/restrictions/${id}`, data)
const deleteAxios = id => axiosAccounting.delete(`/restrictions/${id}`)

export default {
  getAxios,
  postAxios,
  oneAxios,
  putAxios,
  deleteAxios,
}
